<template>
  <b-tab :title="title" active>
    <div class="text-center">
      <b-spinner
        variant="success"
        v-if="loadingValores"
      ></b-spinner>
    </div>
    <div role="tablist" v-if="grupos.length" class="grupo">
      <b-card no-body class="mb-1" v-for="grupo in search" :key="grupo.id">
        <b-card-header header-tag="header" class="p-0" role="tab">
          <b-button block v-b-toggle="`accordion-${grupo.id}`" class="btn btn-link">
            <font-awesome-icon :icon="['fas', 'chevron-down']" style="float: right;" />
            <font-awesome-icon :icon="['fas', 'chevron-up']" style="float: right;"/>
            <div class="fz-14 mb-1" :inner-html.prop="grupo.nome | highlight(buscaValores)" />
            <div class="mr-1">
              <font-awesome-icon :icon="['fas', 'user-friends']" class="mr-1" />
              <span class="mr-3">{{ grupo.min_vidas }} a {{ grupo.max_vidas }}</span>

              <font-awesome-icon :icon="['fas', 'calendar-check']" class="mr-1" v-if="grupo.data_inicio" />
              <span class="mr-3" v-if="grupo.data_inicio">A partir {{ grupo.data_inicio | data }}</span>
              <font-awesome-icon :icon="['fas', 'hourglass-end']" class="mr-1" v-if="grupo.data_fim" />
              <span class="mr-3" v-if="grupo.data_fim"> Até {{ grupo.data_fim | data }}</span>

              <font-awesome-icon :icon="['fas', 'hand-holding-usd']" class="mr-1" v-if="grupo.coparticipacao" />
              <span class="mr-3" v-if="grupo.coparticipacao">Coparticipação</span>
              <span class="mr-3" v-if="grupo.coparticipacao_porcentagem">{{ grupo.coparticipacao_porcentagem | porcentagem }}</span>
              <font-awesome-icon :icon="['fas', 'globe']" class="mr-1" v-if="grupo.compulsorio" />
              <span v-if="grupo.compulsorio">Compulsório</span>
            </div>
          </b-button>
        </b-card-header>
        <b-collapse :visible="grupos.length == 1" :id="`accordion-${grupo.id}`" accordion="valores-accordion" role="tabpanel">
          <b-card-body>
            <TabListValores
              :fields="grupo.fields"
              :items="grupo.items"
            />
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <p v-else-if="!loadingValores" class="text-center h5 mt-4">
      <i class="ti-info-alt h4"></i> <br>
      Nenhum grupo encontrado!
    </p>
  </b-tab>
</template>

<script>
import TabListValores from '@/components/TabListValores'

export default {
  props: [
    'title',
    'loadingValores',
    'grupos',
    'search',
    'buscaValores'
  ],
  components: {
    TabListValores
  }
}
</script>
