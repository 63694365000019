<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card :title="titulo" :subTitle="subtitulo">
          <div>
            <form @submit.prevent>
              <div class="row">
                <div class="col col-md-3 align-self-center">
                  <b-form-group>
                    <b-form-radio-group
                      id="tipo_planos"
                      v-model="filtros.tipo_plano"
                      :options="tipo_planos"
                      buttons
                      name="radios-btn-default"
                      size="sm"
                      label-size="sm"
                      button-variant="primary"
                    ></b-form-radio-group>
                  </b-form-group>
                </div>
                <div class="col col-md-2 align-self-center">
                  <b-form-group>
                    <b-form-radio-group
                      id="contratacoes"
                      v-model="filtros.contratacao"
                      :options="contratacoes"
                      buttons
                      name="radios-btn-default"
                      size="sm"
                      button-variant="primary"
                    ></b-form-radio-group>
                  </b-form-group>
                </div>

               <div class="col col-md-7">
                  <div class="custom-control custom-checkbox" v-show="filtros.tipo_plano != 'o'">
                    <input class="custom-control-input"
                      type="checkbox"
                      id="inlineCheckboxAcom"
                      data-type="acomodacao"
                      @click="toggle"
                    />
                    <label class="custom-control-label" for="inlineCheckboxAcom">
                      Acomodação: {{ textAcom }}
                    </label>
                  </div>

                  <div class="custom-control custom-checkbox" for="inlineCheckboxCop">
                    <input class="custom-control-input"
                      type="checkbox"
                      id="inlineCheckboxCop"
                      data-type="coparticipacao"
                      @click="toggle"
                    />
                    <label class="custom-control-label" for="inlineCheckboxCop">
                      Coparticipação: {{ textCop }}
                    </label>
                  </div>

                  <div class="custom-control custom-checkbox">
                    <input class="custom-control-input"
                      type="checkbox"
                      id="inlineCheckboxCom"
                      data-type="compulsorio"
                      @click="toggle"
                    />
                    <label class="custom-control-label" for="inlineCheckboxCom">
                      Compulsório: {{ textCom }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5 mt-0 mb-0">
                  <v-select id="profissoes"
                    v-model="filtros.entidade_profissao"
                    :filterable="false"
                    :options="entidades_profissoes"
                    :selected="filtros.entidade_profissao"
                    @search="onSearchProfissao"
                    :selectable="option => !option.hasOwnProperty('group')"
                    label="nome"
                    placeholder="Profissão ou Entidade de Classe"
                    class="mt-2 mb-2"
                    v-if="filtros.contratacao == 'a'">
                    <template slot="no-options">Profissão ou Entidade de Classe não localizada</template>
                    <template #option="{ nome, descricao }">
                      {{ nome }}
                      <small v-if="descricao" class="descricao">
                        {{ descricao }}
                      </small>
                    </template>
                  </v-select>
                  <v-select id="cidades"
                    v-model="filtros.cidade"
                    :filterable="false"
                    :options="cidades"
                    :selected="filtros.cidade"
                    @search="onSearchCidade"
                    v-bind:class="{ 'error': !filtros.cidade }"
                    label="cidade"
                    placeholder="Cidades"
                    class="mt-2 mb-2">
                    <template slot="no-options">Cidade não localizada</template>
                    <template slot="option" slot-scope="option">
                      {{ option.cidade }} - {{ option.uf }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.cidade }} - {{ option.uf }}
                    </template>
                  </v-select>
                </div>
                <div class="col-md-7 mt-0 mb-0 v-selected" v-show="filtros.tipo_plano != 'o'">
                  <v-select id="credenciados"
                    v-model="filtros.credenciado"
                    :filterable="false"
                    :options="credenciados"
                    @search="onSearchCredenciado"
                    label="nome"
                    placeholder="Credenciados"
                    class="mt-2 mb-2">
                    <template slot="no-options">Credenciado não localizado</template>
                    <template slot="option" slot-scope="option">
                      {{ option.nome }}
                      <span class="credenciado_detalhes">{{ option.cidade }} - {{ option.uf }}</span>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.nome }}
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="clearfix"></div>
            </form>
          </div>
          <div>
            <div class="row">
              <div class="col-md-12">
                <div class="card-container" v-if="operadoras.length">
                  <div class="card card-logo"
                    v-for="operadora in operadoras"
                    :key="operadora.id">
                      <div class="content"
                        @click="openModalOperadora(operadora)">
                        <img :src="operadoraLogoTipo(operadora)"
                          :alt="operadora.nome"
                          style="max-height: 100%;" />
                      </div>
                  </div>
                </div>
                <p v-else-if="!loadingOperadoras" class="text-center h5 mt-4">
                  <i class="ti-info-alt h4"></i> <br>
                  Nenhum registro encontrado! <br>
                  Por favor, verifique as opções de filtro e tente novamente.
                </p>
                <div class="text-center">
                  <loader :width="'150px'" :height="'150px'" v-if="loadingOperadoras" />
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-operadora-produtos"
      size="lg"
      @show="showModalProdutos"
      @ok="handleOkProdutos"
      ok-only
      ok-variant="success"
      ok-title="Continuar">
      <template v-slot:modal-title>
        {{ operadoraSelecionada.nome }}
      </template>
      <div class="row">
        <div class="col-sm-12 col-12">
          <div class="text-center">
            <loader
              :width="'100px'"
              :height="'100px'"
              v-if="loadingProdutos" />
          </div>
          <b-form-group :state="stateProduto" v-if="produtos.length">
            <b-form-radio
              v-for="p in produtos"
              :key="p.id"
              v-model="produto"
              name="produto"
              :value="p"
              :state="stateProduto"
              v-on:change="handleOkRadioProdutos()">
              {{ p.nome }}
            </b-form-radio>
          </b-form-group>
          <p class="text-center" v-else-if="!loadingProdutos">Nenhum produto encontrado</p>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-operadora-produtos-adesao"
      size="lg"
      @show="showModalProdutosAdesao"
      @ok="handleOkProdutosAdesao"
      ok-only
      ok-variant="success"
      ok-title="Continuar">
      <template v-slot:modal-title>
        {{ operadoraSelecionada.nome }}
      </template>
      <div class="text-center">
        <loader
            :width="'100px'"
            :height="'100px'"
            v-if="loadingProdutos" />
      </div>
      <div class="d-block text-center" v-if="produtosAdesao.length">
        <b-card no-body class="mb-1" v-for="(p, pindex) in produtosAdesao" :key="pindex">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle="`accordion-${p.id}-${p.administradora.id}`" class="btn btn-link">
                    <div class="text-left mb-1">
                        {{ p.nome }} - {{ p.administradora.nome }}
                    </div>
                </b-button>
            </b-card-header>
            <b-collapse :id="`accordion-${p.id}-${p.administradora.id}`" accordion="cenarios-accordion" role="tabpanel">
                <b-card-body>
                    <div class="row">
                        <div class="col-12 col-xs-12 col-sm-6 col-md-4 text-left" v-for="e in p.entidades" :key="e.id">
                            <b-form-radio
                              :id="`entidade-${p.id}-${p.administradora.id}-${e.id}`"
                              v-model="adesao"
                              :name="`entidade-${p.id}-${p.administradora.id}-${e.id}`"
                              :value="`${p.id}#${p.administradora.id}#${e.id}`"
                              :state="stateAdesao">
                              {{ e.nome }}
                              <b-icon class="ml-1"
                                icon="exclamation-circle"
                                :id="`tooltip-target-${p.id}-${p.administradora.id}-${e.id}`"
                                :data-produto-id="p.id"
                                :data-administradora-id="p.administradora.id"
                                :data-entidade-id="e.id"></b-icon>

                              <b-tooltip :target="`tooltip-target-${p.id}-${p.administradora.id}-${e.id}`"
                                triggers="hover">
                                <div :inner-html.prop="tooltip"></div>
                              </b-tooltip>
                            </b-form-radio>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>
      </div>
      <p class="text-center" v-else-if="!loadingProdutos">Nenhum produto encontrado</p>
    </b-modal>
    <b-modal id="modal-produto-grupos"
      size="lg"
      @show="showModalGrupos"
      @ok="handleOkGrupos"
      ok-only
      ok-variant="success"
      ok-title="Continuar">
      <template v-slot:modal-title>
        {{ produto.nome }}
      </template>

      <template #modal-footer="{ ok, cancel }">
        <b-button size="md" variant="outline-primary mr-auto" @click="handleExibirModalProdutos()">
          Voltar
        </b-button> 
        <b-button size="md" variant="success" @click="handleOkGrupos">
          Continuar
        </b-button>
      </template>

      <div class="row">
        <div class="col-sm-12 col-12">
          <div class="text-center">
            <loader
              :width="'100px'"
              :height="'100px'"
              v-if="loadingGrupos" />
          </div>
          <tree ref="tree" :options="opts" v-if="grupos.length">
            <div slot-scope="{ node }" class="node-container">
              <div v-if="node.depth == 0">
                <div class="node-text">
                  <strong>{{ node.text }}</strong>
                </div>
                <div>
                  <font-awesome-icon :icon="['fas', 'user-friends']" class="mr-1" />
                  <span class="mr-3">{{ node.data.min_vidas }} a {{ node.data.max_vidas }}</span>

                  <font-awesome-icon :icon="['fas', 'calendar-check']" class="mr-1" v-if="node.data.data_inicio" />
                  <span class="mr-3" v-if="node.data.data_inicio">A partir {{ node.data.data_inicio | data }}</span>
                  <font-awesome-icon :icon="['fas', 'hourglass-end']" class="mr-1" v-if="node.data.data_fim" />
                  <span class="mr-3" v-if="node.data.data_fim"> Até {{ node.data.data_fim | data }}</span>

                  <font-awesome-icon :icon="['fas', 'hand-holding-usd']" class="mr-1" v-if="node.data.coparticipacao" />
                  <span class="mr-3" v-if="node.data.coparticipacao">Coparticipação</span>
                  <span class="mr-3" v-if="node.data.coparticipacao_porcentagem">{{ node.data.coparticipacao_porcentagem | porcentagem }}</span>
                  <font-awesome-icon :icon="['fas', 'globe']" class="mr-1" v-if="node.data.compulsorio" />
                  <span v-if="node.data.compulsorio">Compulsório</span>
                </div>
              </div>
              <div v-else>
                <div class="node-text">
                  {{ node.text }}
                </div>
              </div>
            </div>
          </tree>
          <p class="text-center" v-else-if="!loadingGrupos">Nenhum grupo encontrado</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { PaperTable } from "@/components"
import lodash from 'lodash'
import Loader from '@/components/Loader'

import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    PaperTable,
    Loader
  },
  data() {
    return {
      filtros: {
        tipo_plano: null,
        contratacao: null,
        coparticipacao: null,
        compulsorio: null,
        acomodacao: null,
        cidade: null,
        credenciado: null,
        entidade_profissao: null,
        faixas: [],
      },
      tipo_busca: null,
      lista_adesao: [
        { text: 'Administradora', value: 'a' },
        { text: 'Entidade', value: 'e' },
        { text: 'Profissão', value: 'p' },
      ],
      loadingProfissoes: [],
      profissoes: [],
      entidades_profissoes: [],
      cidades: [],
      credenciados: [],
      faixa: {
        '00-18': 0,
        '19-23': 0,
        '24-28': 0,
        '29-33': 0,
        '34-38': 0,
        '39-43': 0,
        '44-48': 0,
        '49-53': 0,
        '54-58': 0,
        '59-99': 0
      },
      operadora: null,
      loadingOperadoras: false,
      operadoras: [],
      produtos: [],
      produtosAdesao: [],
      planos: [],
      planosPorRede: [],
      loadingProdutos: false,
      produto: null,
      adesao: null,
      administradora: null,
      entidade: null,
      valores: [],
      grupos: [],
      loadingGrupos: false,
      selected: '',
      operadoraSelecionada: {
        nome: null
      },
      subtitulo: 'Selecione as informações abaixo para filtrar',
      contratacoes: [
        { text: 'PF', value: 'pf' },
        { text: 'PME', value: 'pme' },
        { text: 'Adesão', value: 'a' }
      ] ,
      tipo_planos: [
        { text: 'Saúde', value: 's' },
        { text: 'Odonto', value: 'o'}
      ],
      data: [],
      tooltip: 'Carregando ...',
      opts: {
        checkbox: true,
        store: {
          store: this.$store,
          getter: () => {
            return this.getGroupTree
          },
          dispatcher: (newTree) => {
            this.$store.dispatch('operadora/updateGroupTree', this.$refs.tree.toJSON())
          }
        },
      }
    };
  },
  computed: {
    ...mapGetters('usuario', ['getSession']),
    ...mapGetters(
      'operadora',
      [
        'getGroupTree',
        'getGrupos',
        'getPlanos',
        'getPlanosPorRede'
      ]
    ),
    titulo() {
      return 'SIMP Tabelas <a href="https://suporte.agencialink.com.br/simp-simuladores-online/como-utilizar-simp-tabelas/" \
        target="_blank" \
        class="suporte">\
          <svg aria-hidden="true" \
            focusable="false" \
            data-prefix="fas" \
            data-icon="graduation-cap" \
            role="img" xmlns="http://www.w3.org/2000/svg" \
            viewBox="0 0 640 512" \
            class="svg-inline--fa fa-graduation-cap fa-w-20">\
            <path fill="currentColor" \
              d="M622.34 153.2L343.4 67.5c-15.2-4.67-31.6-4.67-46.79 0L17.66 153.2c-23.54 7.23-23.54 38.36 0 45.59l48.63 14.94c-10.67 13.19-17.23 29.28-17.88 46.9C38.78 266.15 32 276.11 32 288c0 10.78 5.68 19.85 13.86 25.65L20.33 428.53C18.11 438.52 25.71 448 35.94 448h56.11c10.24 0 17.84-9.48 15.62-19.47L82.14 313.65C90.32 307.85 96 298.78 96 288c0-11.57-6.47-21.25-15.66-26.87.76-15.02 8.44-28.3 20.69-36.72L296.6 284.5c9.06 2.78 26.44 6.25 46.79 0l278.95-85.7c23.55-7.24 23.55-38.36 0-45.6zM352.79 315.09c-28.53 8.76-52.84 3.92-65.59 0l-145.02-44.55L128 384c0 35.35 85.96 64 192 64s192-28.65 192-64l-14.18-113.47-145.03 44.56z" \
              class="">\
            </path>\
          </svg>\
        </a>'
    },
    textCop() {
      return this.filtros.coparticipacao > 1 ? 'indiferente' : this.filtros.coparticipacao == 1 ? 'sim' : 'não'
    },
    textCom() {
      return this.filtros.compulsorio > 1 ? 'indiferente' : this.filtros.compulsorio == 1 ? 'sim' : 'não'
    },
    textAcom() {
      return this.filtros.acomodacao > 1 ? 'indiferente' : this.filtros.acomodacao == 1 ? 'apartamento' : 'enfermaria'
    },
    stateProduto() {
      return Boolean(this.produto)
    },
    stateAdesao() {
      return Boolean(this.adesao)
    }
  },
  methods: {
    ...mapActions('usuario', ['saveSession']),
    ...mapActions('profissao', ['buscaProfissao']),
    ...mapActions('cidade', ['buscaCidade']),
    ...mapActions('credenciado', ['buscaCredenciado']),
    ...mapActions('operadora', ['buscaOperadora', 'getOperadoras']),
    ...mapActions('produto', ['buscaProduto']),
    ...mapActions('plano', ['buscaPlano']),
    ...mapActions('administradora', ['buscaAdministradoras']),
    ...mapActions('entidade', ['buscaEntidades']),
    operadoraLogoTipo(operadora) {
      return operadora.logotipoavo ? operadora.logotipoavo : operadora.logotipo
    },
    onSearchProfissao(search, loading) {
      if (search && search.length >= 3) {
        loading(true),
        this.searchProfissao(loading, search, this)
      }
    },
    searchProfissao: _.debounce((loading, search, vm) => {
      vm.entidades_profissoes = []
      vm.buscaProfissao({
        uf: vm.filtros.cidade.uf,
        cidade: '', // vm.filtros.cidade.id,
        search: search
      })
      .then(res => {
        vm.entidades_profissoes = res.map(r => {
          return {
            tipo: r.tipo,
            nome: r.nome,
            descricao: r?.descricao,
            value: r
          }
        })
        loading(false)
      }).catch((err) => loading(false))
    }, 500),
    onSearchCidade(search, loading) {
      if (search) {
        loading(true)
        this.searchCidade(loading, search, this)
      }
    },
    searchCidade: _.debounce((loading, search, vm) => {
      vm.cidades = [];
      vm.buscaCidade(search)
        .then(cidades => {
          vm.cidades = cidades
          loading(false);
        });
    }, 500),
    onSearchCredenciado(search, loading) {
      if (search && this.filtros.cidade) {
        loading(true)
        this.searchCredenciado(loading, search, this)
      }
    },
    searchCredenciado: _.debounce((loading, search, vm) => {
      vm.credenciados = [];
      vm.buscaCredenciado({
        uf: vm.filtros.cidade.uf,
        search: search
      })
      .then(credenciados => {
        vm.credenciados = credenciados
        loading(false);
      })
    }, 500),
    onSearchOperadoras(clear) {
      if (clear) {
        this.$store.state.operadora.operadoras = [];
        this.operadoras = []
      }
      if (this.$store.state.operadora.operadoras.length == 0) {
        this.searchOperadoras(this)
      }
    },
    searchOperadoras: _.debounce(vm => {
      vm.loadingOperadoras = true
      vm.operadoras = []
      if (vm.filtros.cidade && vm.filtros.contratacao) {
        localStorage.setItem('operadora', JSON.stringify(null))
        localStorage.setItem('filters', JSON.stringify([]))
        vm.buscaOperadora({
          uf: vm.filtros.cidade.uf,
          tipo_plano: vm.filtros.tipo_plano,
          contratacao: vm.filtros.contratacao,
          cidade: vm.filtros.cidade ? vm.filtros.cidade.id : '',
          compulsorio: vm.filtros.compulsorio == 2 ? '' : vm.filtros.compulsorio == 1 ? 's' : 'n',
          coparticipacao: vm.filtros.coparticipacao == 2 ? '' : vm.filtros.coparticipacao == 1 ? 's' : 'n',
          acomodacao: vm.filtros.acomodacao == 2 ? '' : vm.filtros.acomodacao == 1 ? 'a' : 'e',
          credenciado: vm.filtros.credenciado ? vm.filtros.credenciado.tipo + vm.filtros.credenciado.id : '',
          entidade: vm.filtros.entidade_profissao?.tipo == 'e' ? vm.filtros.entidade_profissao.value.id : '',
          profissao: vm.filtros.entidade_profissao?.tipo == 'p' ? vm.filtros.entidade_profissao.value.id : '',
          faixas: ''
        })
        .then(res => {
          vm.operadoras = res
          vm.loadingOperadoras = false
        }).catch(err => {
          vm.loadingOperadoras = false
        })
      }
    }, 500),
    getFaixas() {
      let faixas = [];
      Object.keys(this.faixa).forEach((f,i) => faixas[i] = this.faixa[f])
      return faixas
    },
    checked(el) {
      const type = el.getAttribute('data-type');
      if (this.filtros[type] == 0) {
        el.checked = el.readOnly = false
      } else if (this.filtros[type] == 1) {
        el.checked = true
        el.readOnly = false
        el.indeterminate = false
      } else if (this.filtros[type] == 2) {
        el.readOnly = el.indeterminate = true
      }
    },
    dataType(el) {
      const type = el.getAttribute('data-type');
      if (el.readOnly) {
        this.filtros[type] = 0;
        el.checked = el.readOnly = false
      } else if (!el.checked) {
        this.filtros[type] = 2;
        el.readOnly = el.indeterminate = true
      } else {
        this.filtros[type] = 1;
      }
    },
    toggle(e) {
      this.dataType(e.target);
    },
    openModalOperadora(operadora) {
      this.operadoraSelecionada = operadora
      if (this.filtros.contratacao == 'a') {
        this.$bvModal.show('modal-operadora-produtos-adesao')
      } else {
        this.$bvModal.show('modal-operadora-produtos')
      }
    },
    showModalProdutos() {
      this.loadingProdutos = true
      this.produto = null
      this.produtos = []
      this.buscaProduto({
        uf: this.filtros.cidade.uf,
        operadora: this.operadoraSelecionada.id,
        tipo_plano: this.filtros.tipo_plano,
        contratacao: this.filtros.contratacao,
        cidade: this.filtros.cidade ? this.filtros.cidade.id : '',
        compulsorio: this.filtros.compulsorio == 2 ? '' : this.filtros.compulsorio == 1 ? 's' : 'n',
        coparticipacao: this.filtros.coparticipacao == 2 ? '' : this.filtros.coparticipacao == 1 ? 's' : 'n',
        acomodacao: this.filtros.acomodacao == 2 ? '' : this.filtros.acomodacao == 1 ? 'a' : 'e',
        credenciado: this.filtros.credenciado ? this.filtros.credenciado.tipo + this.filtros.credenciado.id : '',
        entidade: this.filtros.entidade_profissao?.tipo == 'e' ? this.filtros.entidade_profissao.value.id : '',
        profissao: this.filtros.entidade_profissao?.tipo == 'p' ? this.filtros.entidade_profissao.value.id : '',
        faixas: ''
      }).then(rprodutos => {
        this.produtos = rprodutos
        this.loadingProdutos = false
        if (this.produtos.length == 1) {
          this.produto = this.produtos[0]
          this.$bvModal.hide('modal-operadora-produtos')
          this.$bvModal.show('modal-produto-grupos')
        }
      }).catch(err => { this.loadingProdutos = false })
    },
    handleOkProdutos(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmitProduto()
    },
    handleOkRadioProdutos() {
      this.handleSubmitProduto();
    },
    checkFormValidityProduto() {
      return this.produto !== null
    },
    handleSubmitProduto() {
      if (!this.checkFormValidityProduto()) {
        return
      }
      this.$nextTick(() => {
       // this.$bvModal.hide('modal-operadora-produtos')
        this.$bvModal.show('modal-produto-grupos')
      })
    },
    async showModalProdutosAdesao() {
      this.loadingProdutos = true
      this.produto = null
      this.administradora = null
      this.entidade = null
      this.produtosAdesao = []
      await this.buscaProduto({
        uf: this.filtros.cidade.uf,
        operadora: this.operadoraSelecionada.id,
        tipo_plano: this.filtros.tipo_plano,
        contratacao: this.filtros.contratacao,
        cidade: this.filtros.cidade ? this.filtros.cidade.id : '',
        compulsorio: this.filtros.compulsorio == 2 ? '' : this.filtros.compulsorio == 1 ? 's' : 'n',
        coparticipacao: this.filtros.coparticipacao == 2 ? '' : this.filtros.coparticipacao == 1 ? 's' : 'n',
        acomodacao: this.filtros.acomodacao == 2 ? '' : this.filtros.acomodacao == 1 ? 'a' : 'e',
        credenciado: this.filtros.credenciado ? this.filtros.credenciado.tipo + this.filtros.credenciado.id : '',
        entidade: this.filtros.entidade_profissao?.tipo == 'e' ? this.filtros.entidade_profissao.value.id : '',
        profissao: this.filtros.entidade_profissao?.tipo == 'p' ? this.filtros.entidade_profissao.value.id : '',
        faixas: ''
      }).then(rprodutos => {
        this.produtosAdesao = rprodutos.reduce((acc, obj) => {
          if (!Array.isArray(acc))
            acc = []
          let produtoId = obj.id
          let entidade = obj.entidade
          let administradoraId = obj.administradora.id
          let i = acc.findIndex(p => p.id === produtoId && p.administradora.id === administradoraId)
          if (i == -1) {
            acc.push({ id: obj.id, nome: obj.nome, administradora: obj.administradora, entidades: [entidade]})
          } else {
            acc[i].entidades.push(entidade)
            acc[i].entidades.sort((a, b) => a.nome > a.nome ? 1 : a.nome < b.nome  ? -1 : 0)
          }
          return acc
        }, {});
        if (this.produtosAdesao.length == 1) {
          if (this.produtosAdesao[0].entidades.length == 1) {
            this.produto = this.produtosAdesao[0]
            this.administradora = this.produto.administradora
            this.entidade = this.produto.entidades[0]
            this.$bvModal.hide('modal-operadora-produtos')
            this.$bvModal.show('modal-produto-grupos')
          }
        }
        this.loadingProdutos = false
      }).catch(err => { this.loadingProdutos = false })
    },
    handleOkProdutosAdesao(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmitProdutoAdesao()
    },
    handleSubmitProdutoAdesao() {
      if (!this.checkFormValidityProdutoAdesao()) {
        return
      }
      let selected = this.adesao.split('#'),
          produtoId = selected[0],
          adminId = selected[1],
          entidadeId = selected[2]
          console.log(selected)
      if (produtoId) {
        let produtos = this.produtosAdesao.filter(p => p.id == produtoId)
        let produto = produtos.find(p => p.administradora.id == adminId)
        this.produto = produto
        this.administradora = produto.administradora
        this.entidade = produto.entidades.find(e => e.id == entidadeId)
        this.$nextTick(() => {
          this.$bvModal.hide('modal-operadora-produtos-adesao')
          this.$bvModal.show('modal-produto-grupos')
        })
      }
    },
    checkFormValidityProdutoAdesao() {
      return this.adesao !== null
    },
    showModalGrupos() {
      this.$store.dispatch('operadora/updateGroupTree', [])
      let groupTree = [];
      this.loadingGrupos = true
      this.planosPorRede = []
      this.grupos = [];
      this.buscaPlano({
        uf: this.filtros.cidade.uf,
        produto: this.produto.id,
        tipo_plano: this.filtros.tipo_plano,
        contratacao: this.filtros.contratacao,
        cidade: this.filtros.cidade ? this.filtros.cidade.id : '',
        compulsorio: this.filtros.compulsorio == 2 ? '' : this.filtros.compulsorio == 1 ? 's' : 'n',
        coparticipacao: this.filtros.coparticipacao == 2 ? '' : this.filtros.coparticipacao == 1 ? 's' : 'n',
        acomodacao: this.filtros.acomodacao == 2 ? '' : this.filtros.acomodacao == 1 ? 'a' : 'e',
        credenciado: this.filtros.credenciado ? this.filtros.credenciado.tipo + this.filtros.credenciado.id : '',
        entidade: this.entidade ? this.entidade.id : '',
        profissao: '', //this.filtros.entidade_profissao?.tipo == 'p' ? this.filtros.entidade_profissao.value.id : '',
        administradora: this.administradora ? this.administradora.id : '',
        faixas: '',
        detalhes: 'n'
      }).then(grupos => {
        let planos = []
        grupos.map(grupo => {
          groupTree.push({
            text: grupo.nome,
            state: { checked: true },
            data: grupo,
            children: grupo.planos.map(plano => {
              planos.push(plano)
              return {
                text: plano.nome,
                state: { checked: true },
                data: plano,
              }
            })
          })
        })
        this.loadingGrupos = false
        this.planos = planos

        // Recupera os valores dos planos
        let keyValores = grupos
          .find(g => g.hasOwnProperty('planos'))
          ?.planos
          .find(p => p.hasOwnProperty('valores'))
          ?.valores
        // Recupera a chave dos valores unico ou idades
        this.valores = keyValores ? Object.keys(keyValores) : [];
        // Define a chave que sera utilizada no objeto grupo para exibição da tabela
        let keyValor = this.valores.indexOf('unico') != -1 ? 'unico' : 'idade'

        if (keyValor == 'unico') {
          this.valores = []
          this.grupos = grupos.map(grupo => {
            grupo['fields'] = [
              { key: 'plano', stickyColumn: true, isRowHeader: true },
              { key: 'valor', label: 'Planos' }
            ];
            grupo['items'] = grupo.planos.reduce((acc, plano) => {
              if (!Array.isArray(acc)) acc = []
              this.valores.push(plano.nome)
              let valores = Object.keys(plano.valores)
              valores.forEach((k) => {
                let arr = { 'plano': plano.nome, 'valor': plano.valores[k] }
                acc.push(arr)
              })
              return acc
            }, {});
            return grupo
          })
        } else {
          this.grupos = grupos.map(grupo => {
            grupo['fields'] = grupo.planos.reduce((acc, plano) => {
              if (!Array.isArray(acc))
                acc = [
                  { key: keyValor, stickyColumn: true, isRowHeader: true },
                ]
              acc.push({ key: plano.id, label: plano.nome })
              return acc
            }, {});
            grupo['items'] = grupo.planos.reduce((acc, plano) => {
              if (!Array.isArray(acc)) acc = []
              let valores = Object.keys(plano.valores)
              valores.forEach((k) => {
                let arr = {}
                let i = acc.findIndex(p => p[keyValor] === k)
                arr[plano.id] = plano.valores[k]
                if (i == -1 ) {
                  let o = {...arr}
                  o[keyValor] = k
                  acc.push(o)
                } else {
                  acc[i] = {
                    ...acc[i],
                    ...arr
                  }
                }
              })
              return acc
            }, {});
            return grupo
          })
        }
        this.$store.dispatch('operadora/updateGroupTree', groupTree)
        const planosArr = new Map();
        grupos.forEach((grupo) => {
          let data_inicio = new Date(grupo.data_inicio),
            now = new Date()
          data_inicio.setDate(data_inicio.getDate() + 1)
          grupo.data_inicio = data_inicio <= now ? false : grupo.data_inicio
          grupo.planos.forEach((plano) => {
            const collection = planosArr.get(plano.rede_id);
            if (!collection) {
                planosArr.set(plano.rede_id, [plano]);
            } else {
                var i = collection.find(c => c.nome == plano.nome)
                if (!i) collection.push(plano)
            }
          })
        })
        this.planosPorRede = Array.from(planosArr)
          .map(p => p[1])
          .flat()
      }).catch(err => { this.loadingGrupos = false })
    },
    async handleOkGrupos(bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.handleSubmitGrupos()
    },
    handleExibirModalProdutos() {
      this.$bvModal.hide('modal-produto-grupos')
    },
    checkFormValidityGrupos() {
      // TODO - Ao menos um checked
      return this.getGroupTree.length > 0
    },
    async handleSubmitGrupos() {
      if (!this.checkFormValidityGrupos()) {
        return
      }
      this.operadoraSelecionada['tipo_plano'] = this.filtros.tipo_plano
      this.operadoraSelecionada['contratacao'] = this.filtros.contratacao
      this.operadoraSelecionada['coparticipacao'] = this.filtros.coparticipacao
      this.operadoraSelecionada['compulsorio'] = this.filtros.compulsorio
      this.operadoraSelecionada['acomodacao'] = this.filtros.acomodacao
      this.operadoraSelecionada['cidade'] = this.filtros.cidade
      this.operadoraSelecionada['credenciado'] = this.filtros.credenciado
      this.operadoraSelecionada['produto'] = this.produto
      this.operadoraSelecionada['administradora'] = this.administradora
      this.operadoraSelecionada['entidade'] = this.entidade
      this.operadoraSelecionada['planos'] = this.getPlanos
      this.operadoraSelecionada['planosPorRede'] = this.getPlanosPorRede
      this.operadoraSelecionada['valores'] = this.valores
      this.operadoraSelecionada['grupos'] = this.getGrupos
      this.operadoraSelecionada['groupTree'] = this.getGroupTree
      this.$store.dispatch('cidade/selectedCidade', this.filtros.cidade)
      this.$store.dispatch('credenciado/selectedCredenciado', this.filtros.credenciado)
      this.$store.dispatch('operadora/selectedOperadora', this.operadoraSelecionada)
      this.$store.dispatch('operadora/selectedProduto', this.produto)

      localStorage.setItem('operadora', JSON.stringify(this.operadoraSelecionada))

      const id = localStorage.getItem(process.env.VUE_APP_CLIENT_ID)
      const json = JSON.parse(id)
      const user = json?.user

      this.operadoraSelecionada['user'] = {
        id: user.id,
        barra_url: user.barra_url,
        empresa: user.empresa,
        login: user.login,
        logotipo: user.logotipo,
        name: user.name,
        uf: user.uf
      }

      localStorage.removeItem('cotacao_id')
      localStorage.removeItem('cotacao_hash')

      await this.saveSession({
        token: user.token,
        data: this.operadoraSelecionada
      })

      const resp = this.getSession

      console.log(resp)

      await this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
        this.$router.push({
          name: 'tabelaDetalhe',
          params: {
            id: this.operadoraSelecionada.id
          },
          query: {
            tipo_plano: this.filtros.tipo_plano,
            contratacao: this.filtros.contratacao,
            cidade: this.filtros.cidade,
            coparticipacao: this.filtros.coparticipacao,
            compulsorio: this.filtros.compulsorio,
            acomodacao: this.filtros.acomodacao,
            credenciado: this.filtros.credenciado,
            entidade: this.filtros.entidade,
            profissao: this.filtros.profissao,
            hash: resp.id
          }
        })
      })
    },
  },
  watch: {
    filtros: {
        deep: true,
        handler(filtros, old) {
          if (filtros.tipo_plano !== this.$route.query.tipo_plano) {
            this.filtros.credenciado = null
          }
          if (filtros.contratacao !== this.$route.query.contratacao) {
            this.filtros.entidade_profissao = null
            this.$route.query.entidade = ''
            this.$route.query.profissao = ''
            this.administradora = null
            this.entidade = null
          }
          localStorage.setItem('filtros', JSON.stringify(filtros))
          this.$router.replace({
            query: {
              tipo_plano: this.filtros.tipo_plano,
              contratacao: this.filtros.contratacao,
              cidade: this.filtros.cidade ? this.filtros.cidade.id : '',
              compulsorio: this.filtros.compulsorio == 2 ? '' : this.filtros.compulsorio == 1 ? 's' : 'n',
              coparticipacao: this.filtros.coparticipacao == 2 ? '' : this.filtros.coparticipacao == 1 ? 's' : 'n',
              credenciado: this.filtros.credenciado ? this.filtros.credenciado.id : '',
              acomodacao: this.filtros.acomodacao == 2 ? '' : this.filtros.acomodacao == 1 ? 'a' : 'e',
              entidade: this.filtros.entidade_profissao?.tipo == 'e' ? this.filtros.entidade_profissao.value.id : '',
              profissao: this.filtros.entidade_profissao?.tipo == 'p' ? this.filtros.entidade_profissao.value.id : '',
              faixas: ''
            }
          });
          this.onSearchOperadoras(true)
        }
    }
  },
  mounted() {
    this.$nextTick(function () {
      const json = localStorage.getItem('filtros')
      const filtros = JSON.parse(json)

      this.filtros.tipo_plano = this.$route.query.tipo_plano ?
        this.$route.query.tipo_plano :
        filtros?.tipo_plano || 's'

      this.filtros.contratacao = this.$route.query.contratacao ?
        this.$route.query.contratacao :
        filtros?.contratacao || 'pf'

      let queryCoparticipacao = this.$route.query.coparticipacao
      this.filtros.coparticipacao = this.$route.query.hasOwnProperty('coparticipacao') ?
        (queryCoparticipacao == "" ? 2 : (queryCoparticipacao == 's' ? 1 : 0)) :
        (Number.isInteger(filtros?.coparticipacao) ? filtros.coparticipacao : 2)

      let queryCompulsorio = this.$route.query.compulsorio
      this.filtros.compulsorio = this.$route.query.hasOwnProperty('compulsorio') ?
        (queryCompulsorio == "" ? 2 : (queryCompulsorio == 's' ? 1 : 0)) :
        (Number.isInteger(filtros?.compulsorio) ? filtros.compulsorio : 2)

      let queryAcomodacao = this.$route.query.acomodacao
      this.filtros.acomodacao = this.$route.query.hasOwnProperty('acomodacao') ?
        (queryAcomodacao == "" ? 2 : (queryAcomodacao == 's' ? 1 : 0)) :
        (Number.isInteger(filtros?.acomodacao) ? filtros.acomodacao : 2)
      /*pegando a cidade e uf do usuario*/
      const ujson = JSON.parse(localStorage.getItem(process.env.VUE_APP_CLIENT_ID))
      const user = ujson?.user
      /*pegando a cidade e uf do usuario*/
    
      this.filtros.cidade = filtros?.cidade || { "id":user.cidade_id,"uf":user.uf,"cidade":user.cidade }
      this.filtros.credenciado = filtros?.credenciado
      this.checked(document.getElementById('inlineCheckboxCop'))
      this.checked(document.getElementById('inlineCheckboxCom'))
      this.checked(document.getElementById('inlineCheckboxAcom'))

      this.onSearchOperadoras(false)
    })
    let vm = this
    this.$root
      .$off('bv::tooltip::show')
      .$on('bv::tooltip::show', async bvEvent => {
      let target  = bvEvent.target,
          // produtoId = target.dataset.produtoId,
          // administradoraId = target.dataset.administradoraId,
          entidadeId = target.dataset.entidadeId

      vm.tooltip = '<span aria-hidden="true" class="spinner-border text-success"></span>'
      await vm.buscaEntidades({
        uf: vm.filtros.cidade.uf,
        entidadeId
      }).then(e => {
        console.log(e)
        // vm.tooltip = '<p style="font-size:10px; text-align:left;">' + Profissao.descricao + '</p>'
        vm.tooltip = '<ul style="font-size:12px; text-align:left;">'
        vm.tooltip += e.profissoes.slice(0, 9).map(p => '<li><b>' + p.nome + '</b></li>').join('')
        if (e.profissoes.length > 10) {
          vm.tooltip += '<li><b>e outras ' + (e.profissoes.length - 10) + ' profissões </b></li>'
        }
        vm.tooltip += '</ul>'
      })
    })
  }
};
</script>
<style>
.error > div {
  border: 1px solid red;
}
.card label.btn-primary {
  color: #555;
}
label.btn-primary {
  background-color: #FFFFFF !important;
  border-color: #A4A4A4 !important;
  border: solid 1px;
}
.btn-sm, .btn-group-sm > .btn {
  font-size: 0.7rem !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  background-color: #009b3a !important;
  color: #FFFFFF !important;
  border-color: #009b3a !important;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: #009b3a !important;
  color: #FFFFFF !important;
  border-color: #009b3a !important;
}
.card-container {
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.card-logo {
  padding: 12px;
  color: #757575;
  border-radius: 5px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12),0 3px 1px -2px rgba(0,0,0,.2);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12),0 3px 1px -2px rgba(0,0,0,.2);
  width: 150px;
  height: 112.5px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
div.v-select ul[role='listbox'] li small {
    display: block;
    color: rgba(0,0,0,.6);
    font-size: 14px;
}
@media only screen and (min-width: 320px) {
  .card .card-logo {
    width: 78px;
    height: 78px;
    padding: 2px;
    margin: 2px !important;
  }
}
@media only screen and (min-width: 345px) {
  .card .card-logo {
    width: 96px;
    height: 96px;
    padding: 2px;
    margin: 2px !important;
  }
}
@media only screen and (min-width: 495px) {
  .card .card-logo {
    width: 127px;
    height: 110px;
    margin: 7px !important;
  }
}
.suporte {
  color: #27a9f7;
  border: 1px solid #27a9f7;
  border-radius: 50%;
  font-size: 17px;
  padding: 4px;
}
.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.credenciado_detalhes {
  display: block;
  color:#666;
}
</style>
