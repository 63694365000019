import ProdutoService from '@/services/produto.service'

export default {
  namespaced: true,
  state: {
    produto: null,
    produtos: [],
  },
  mutations: {
    setProdutos(state, produtos) {
      state.produtos = produtos
    },
    setProduto(state, produto) {
      state.produto = produto
    }
  },
  actions: {
    buscaProduto(
      { commit },
      {
        uf,
        operadora,
        tipo_plano,
        contratacao,
        cidade,
        compulsorio,
        coparticipacao,
        acomodacao,
        credenciado,
        entidade,
        profissao,
        faixas
      }
    ) {
      return ProdutoService.busca(
        uf,
        operadora,
        tipo_plano,
        contratacao,
        cidade,
        compulsorio,
        coparticipacao,
        acomodacao,
        credenciado,
        entidade,
        profissao,
        faixas
      ).then(resp => {
        commit('setProdutos', resp)
        return Promise.resolve(resp);
      }, error => {
        return Promise.reject(error);
      })
    }
  },
  getters: {
      getProduto(state) {
        return state.produto
      },
      getProdutos(state) {
          return state.produtos
      }
  }
}
