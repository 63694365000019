import Vue from 'vue'

class PlanoService {
  // https://api.simp.agencialink.com/planos/sp/3947/s/pf/?compulsorio=&coparticipacao=
  busca(
    uf,
    produto,
    tipo_plano,
    contratacao,
    cidade,
    compulsorio,
    coparticipacao,
    acomodacao,
    credenciado,
    entidade,
    profissao,
    administradora,
    faixas,
    detalhes
  ) {
    let t = contratacao == 'a' && tipo_plano == 's' ? 'a' : tipo_plano,
        c = contratacao == 'a' ? 'pf' : contratacao,
        a = contratacao == 'a' ? '1' : '0'
    let params = [
      { key: 'compulsorio', value: compulsorio },
      { key: 'coparticipacao', value: coparticipacao },
      { key: 'acomodacao', value: acomodacao },
      { key: 'credenciado', value: credenciado },
      { key: 'entidade', value: entidade },
      { key: 'profissao', value: profissao },
      { key: 'administradora', value: administradora },
      { key: 'detalhes', value: detalhes },
      { key: 'adesao', value: a }
    ]
      .map(p => p.key+'='+(p.value||''))
      .join('&');
    let paramsFaixas = faixas ? params + '&' + faixas.map((f,i) => `faixa[${i}]=${f}`).join('&') : params;
    return Vue.prototype.$http.get(
      `planos/${uf}/${produto}/${t}/${c}/${cidade||''}?${paramsFaixas}`
    )
    .then(response => {
      return response.data;
    });
  }
}

export default new PlanoService();
