import OperadoraService from '@/services/operadora.service'

export default {
  namespaced: true,
  state: {
    tabs: [],
    tabsDefault: [
      { key: 'valores', show: true },
      { key: 'rede', show: true },
      { key: 'reembolso', show: true },
      { key: 'carencia', show: true },
      { key: 'proc_obrigatorios', show: true },
      { key: 'proc_diferenciados', show: true },
      { key: 'docs', show: true },
      { key: 'info', show: true },
      { key: 'arquivos', show: true }
    ],
    loadingOperadoras: false,
    operadora: null,
    operadoras: [],
    produto: null,
    loadingPlanos: false,
    planos: [],
    planosPorRede: [],
    operadoraTree: [],
    grupoTree: [],
    loadingGrupos: false,
    grupos: [],
    localidades: [],
  },
  mutations: {
    setTabs(state, tabs) {
      state.tabs = tabs
    },
    setLoadingOperadoras(state, loading) {
      state.loadingOperadoras = loading
    },
    setOperadora(state, operadora) {
      state.operadora = operadora
    },
    setOperadoras(state, operadoras) {
      state.operadoras = operadoras
    },
    setProduto(state, produto) {
      state.produto = produto
    },
    setLoadingPlanos(state, loading) {
      state.loadingPlanos = loading
    },
    setPlanos(state, planos) {
      state.planos = planos
    },
    setPlanosPorRede(state, planos) {
      state.planosPorRede = planos
    },
    setLocalidades(state, localidades) {
      state.localidades = localidades
    },
    updateTreeStore(state, newTree) {
      state.operadoraTree = newTree
    },
    updateGroupTreeStore(state, newTree) {
      state.grupoTree = newTree
    },
    setLoadingGrupos(state, loading) {
      state.loadingGrupos = loading
    },
    setGrupos(state, grupos) {
      state.grupos = grupos
    }
  },
  actions: {
    selectedOperadora({ commit }, operadora) {
      commit('setOperadora', operadora)
    },
    selectedProduto({ commit }, produto) {
      commit('setProduto', produto)
    },
    buscaOperadora(
      { commit },
      {
        uf,
        tipo_plano,
        contratacao,
        cidade,
        compulsorio,
        coparticipacao,
        acomodacao,
        credenciado,
        entidade,
        profissao,
        faixas
      }
    ) {
      commit('setLoadingOperadoras', true)
      return OperadoraService.busca(
        uf,
        tipo_plano,
        contratacao,
        cidade,
        compulsorio,
        coparticipacao,
        acomodacao,
        credenciado,
        entidade,
        profissao,
        faixas
      ).then(resp => {
        commit('setLoadingOperadoras', false)
        commit('setOperadoras', resp)
        return Promise.resolve(resp);
      }, error => {
        return Promise.reject(error);
      })
    },
    updateOperadora(context, operadora) {
      context.commit('setOperadora', operadora)
    },
    updateTree(context, tree) {
      context.commit('updateTreeStore', tree)
    },
    updateLocalidades(context, localidades) {
      context.commit('setLocalidades', localidades)
    },
    updateGroupTree(context, tree) {
      context.commit('setLoadingGrupos', true)
      context.commit('updateGroupTreeStore', tree)
      let grupos = []
      context.state.planos = []
      tree.forEach(grupo => {
        if (grupo.state.checked || grupo.state.indeterminate) {
          let planos = []
          grupo.children.forEach(plano => {
            if (plano.state.checked) {
              let p = {
                id: plano.data.id,
                rede_id: plano.data.rede_id,
                nome: plano.data.nome,
                contratacao: plano.data.contratacao,
                valores: plano.data.valores,
                acomodacao: plano.data.acomodacao
              }
              planos.push(p)
              context.state.planos.push(p)
            }
          })
          grupos.push({
            id: grupo.data.id,
            nome: grupo.data.nome,
            compulsorio: grupo.data.compulsorio,
            coparticipacao: grupo.data.coparticipacao,
            coparticipacao_porcentagem: grupo.data.coparticipacao_porcentagem,
            acomodacao: grupo.data.acomodacao,
            min_vidas: grupo.data.min_vidas,
            max_vidas: grupo.data.max_vidas,
            data_inicio: grupo.data.data_inicio,
            data_fim: grupo.data.data_fim,
            planos: planos,
            redes: grupo.data.redes,
          })
        }
      })
      context.commit('setLoadingGrupos', false)
      context.commit('setLoadingPlanos', true)
      // Recupera os valores dos planos
      let keyValores = grupos
        .find(g => g.hasOwnProperty('planos'))
        ?.planos
        .find(p => p.hasOwnProperty('valores'))
        ?.valores
      // Recupera a chave dos valores unico ou idades
      let valores = keyValores ? Object.keys(keyValores) : [];
      // Define a chave que sera utilizada no objeto grupo para exibição da tabela
      let keyValor = valores.indexOf('unico') != -1 ? 'unico' : 'idade'
      if (keyValor == 'unico') {
        grupos.map(grupo => {
          grupo['fields'] = [
            { key: 'plano', stickyColumn: true, isRowHeader: true },
            { key: 'valor', label: 'Planos' }
          ];
          grupo['items'] = grupo.planos.reduce((acc, plano) => {
            if (!Array.isArray(acc)) acc = []
            let valores = Object.keys(plano.valores)
            valores.forEach((k) => {
              let arr = { 'plano': plano.nome, 'valor': plano.valores[k] }
              acc.push(arr)
            })
            return acc
          }, {});
          return grupo
        })
      } else {
        grupos.map(grupo => {
          grupo['fields'] = grupo.planos.reduce((acc, plano) => {
            if (!Array.isArray(acc))
              acc = [
                { key: keyValor, stickyColumn: true, isRowHeader: true },
              ]
            acc.push({ key: plano.id, label: plano.nome })
            return acc
          }, {});
          grupo['items'] = grupo.planos.reduce((acc, plano) => {
            if (!Array.isArray(acc)) acc = []
            let valores = Object.keys(plano.valores)
            valores.forEach((k) => {
              let arr = {}
              let i = acc.findIndex(p => p[keyValor] === k)
              arr[plano.id] = plano.valores[k]
              if (i == -1 ) {
                let o = {...arr}
                o[keyValor] = k
                acc.push(o)
              } else {
                acc[i] = {
                  ...acc[i],
                  ...arr
                }
              }
            })
            return acc
          }, {});
          return grupo
        })
      }
      context.commit('setGrupos', grupos)
      const planos = new Map();
      grupos.forEach((grupo) => {
        grupo.planos.forEach((plano) => {
          const collection = planos.get(plano.rede_id);
          if (!collection) {
              planos.set(plano.rede_id, [plano]);
          } else {
              var i = collection.find(c => c.nome == plano.nome)
              if (!i) collection.push(plano)
          }
        })
      })
      context.state.planosPorRede = Array.from(planos)
        .map(p => p[1])
        .flat()
      context.commit('setLoadingPlanos', false)
    },
  },
  getters: {
    getTabs(state) {
      return state.tabs.length > 0 ? state.tabs : state.tabsDefault
    },
    getLoadingOperadoras(state) {
      return state.getLoadingOperadoras
    },
    getOperadora(state) {
      return state.operadora
    },
    getOperadoras(state) {
      return state.operadoras
    },
    getProduto(state) {
      return state.produto
    },
    getLoadingPlanos(state) {
      return state.loadingPlanos
    },
    getPlanos(state) {
      return state.planos
    },
    getPlanosPorRede(state) {
      return state.planosPorRede
    },
    tree(state) {
      return state.operadoraTree
    },
    getGroupTree(state) {
      return state.grupoTree
    },
    getLoadingGrupos(state) {
      return state.loadingGrupos
    },
    getGrupos(state) {
      return state.grupos
    }
  }
}
